import React from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaWhatsapp,
  FaInstagram,
} from "react-icons/fa";
import { FiPhone, FiMail, FiMapPin } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer className="bg-gray-100 py-8">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Logo and Social Links */}
          <div>
            <h2 className="text-2xl font-bold mb-4">Travok Travel</h2>
            <p className="text-gray-600 mb-4">
              Rapidiously myocardinate cross-platform intellectual capital
              model. Appropriately create interactive infrastructures.
            </p>
            <div className="flex space-x-4">
              <a href="#" className="text-[#ED7D31] hover:text-blue-600">
                <FaFacebookF size={24} />
              </a>
              <a href="#" className="text-[#ED7D31] hover:text-blue-600">
                <FaTwitter size={24} />
              </a>
              <a href="#" className="text-[#ED7D31] hover:text-blue-600">
                <FaLinkedinIn size={24} />
              </a>
              <a href="#" className="text-[#ED7D31] hover:text-green-600">
                <FaWhatsapp size={24} />
              </a>
              <a href="#" className="text-[#ED7D31] hover:text-pink-600">
                <FaInstagram size={24} />
              </a>
            </div>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-xl font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <a href="#" className="text-gray-600 hover:text-blue-600">
                  Home
                </a>
              </li>
              <li>
                <a href="/about" className="text-gray-600 hover:text-blue-600">
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="/service"
                  className="text-gray-600 hover:text-blue-600"
                >
                  Our Service
                </a>
              </li>
              <li>
                <a
                  href="/terms-of-service"
                  className="text-gray-600 hover:text-blue-600"
                >
                  Terms of Service
                </a>
              </li>
              <li>
                <a href="/faq" className="text-gray-600 hover:text-blue-600">
                  FAQ
                </a>
              </li>
            </ul>
          </div>

          {/* Address */}
          <div>
            <h3 className="text-xl font-semibold mb-4">Address</h3>
            <ul className="space-y-3">
              <li className="flex items-center space-x-3">
                <FiPhone size={20} className="text-[#ED7D31]" />
                <span className="text-gray-600">+01 234 567 890</span>
              </li>
              <li className="flex items-center space-x-3">
                <FiPhone size={20} className="text-[#ED7D31]" />
                <span className="text-gray-600">+09 876 543 210</span>
              </li>
              <li className="flex items-center space-x-3">
                <FiMail size={20} className="text-[#ED7D31]" />
                <span className="text-gray-600">info@travoktravel.com</span>
              </li>
              <li className="flex items-center space-x-3">
                <FiMail size={20} className="text-[#ED7D31]" />
                <span className="text-gray-600">support@travoktravel.com</span>
              </li>
              <li className="flex items-center space-x-3">
                <FiMapPin size={30} className="text-[#ED7D31]" />
                <div>
                  <span className="text-gray-600">
                    Güller Pınarı, Eşref Kahvecioğlu Cd.
                  </span>
                  <span className="text-gray-600"> No: 39, Alanya/Antalya</span>
                </div>
              </li>
            </ul>
          </div>

          {/* Location */}
          <div>
            <h3 className="text-xl font-semibold mb-4">Location</h3>
            <div className="w-full">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3205.3275859275436!2d32.00524925454704!3d36.54621354946594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14dc9863a92cc475%3A0xb69cf8f10a1128a7!2sTravok!5e0!3m2!1str!2str!4v1729072178566!5m2!1str!2str"
                width="100%"
                height="200"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
