import React, { useState, useEffect } from "react";
import Lottie from "lottie-react"; // Import Lottie
import callAnimation from "../assets/animations/call.json"; // Import the animation file

const ContactModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showElements, setShowElements] = useState(true); // State for controlling the visibility

  useEffect(() => {
    // Hide elements after 10 seconds
    const timer = setTimeout(() => {
      setShowElements(false);
    }, 10000);

    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, []);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {/* Lottie Animation and Message Box */}
      {showElements && (
        <div className="fixed right-5 bottom-20 z-50 flex flex-col items-end">
          {" "}
          {/* items-end sağ hizalama */}
          {/* Message Box */}
          <div className="bg-white text-gray-700 p-3 rounded-lg shadow-md mb-2 w-64 text-center">
            <p>Hi! Would you like to get in touch with us?</p>
          </div>
          {/* Animation */}
          <div className="w-16 h-16">
            {" "}
            {/* Boyut küçültüldü */}
            <Lottie animationData={callAnimation} loop={true} autoplay={true} />
          </div>
        </div>
      )}

      {/* Call Button */}
      <button
        onClick={toggleModal}
        className="fixed right-5 bottom-5 bg-[#ED7D31] text-white p-4 rounded-full shadow-lg hover:bg-[#6C5F5B] focus:ring-4 focus:outline-none focus:ring-blue-300 z-50"
      >
        Shall We Call You?
      </button>

      {isModalOpen && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          onClick={closeModal}
        >
          <div
            className="relative p-4 w-full max-w-2xl bg-white rounded-lg shadow dark:bg-gray-700 mx-4 sm:mx-8 lg:mx-24"
            onClick={(e) => e.stopPropagation()}
          >
            {/* Modal Header */}
            <div className="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Contact Us
              </h3>
              <button
                onClick={closeModal}
                className="text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-2.5 dark:hover:bg-gray-600 dark:hover:text-white"
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="w-5 h-5"
                >
                  <line x1="18" y1="6" x2="6" y2="18" />
                  <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
              </button>
            </div>

            {/* Modal Body */}
            <div className="p-6 space-y-4">
              <form className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Phone
                  </label>
                  <input
                    type="tel"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500 sm:text-sm dark:bg-gray-800 dark:border-gray-600 dark:text-white"
                    placeholder="+1 234 567 890"
                  />
                </div>
              </form>
            </div>

            {/* Modal Footer */}
            <div className="flex items-center p-4 border-t dark:border-gray-600">
              <button
                onClick={closeModal}
                className="bg-orange-600 text-white px-5 py-2.5 rounded-lg hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-orange-300 dark:focus:ring-orange-800"
              >
                Submit
              </button>
              <button
                onClick={closeModal}
                className="ml-3 text-gray-900 bg-white border border-gray-200 px-5 py-2.5 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactModal;
