import React, { useState, useRef, useEffect } from "react";
import {
  FaPhoneAlt,
  FaTelegramPlane,
  FaEnvelope,
  FaWhatsapp,
} from "react-icons/fa";

const SpeedDial = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dialRef = useRef(null); // SpeedDial'in tümünü referansla
  const buttonRef = useRef(null); // Toggle butonu için ayrı bir referans

  const toggleDial = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Eğer tıklama SpeedDial'in içindeyse veya toggle butonundaysa bir şey yapma
      if (
        dialRef.current?.contains(event.target) ||
        buttonRef.current?.contains(event.target)
      ) {
        return;
      }
      // Dışarı tıklama algılanırsa kapat
      setIsOpen(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="fixed bottom-6 left-6 z-50 mb-12 md:mb-4">
      {/* Speed Dial Menü */}
      <div
        ref={dialRef} // Menü için referans
        className={`flex flex-col items-center space-y-3 mb-4 ${
          isOpen ? "block" : "hidden"
        }`}
      >
        <a
          href="tel:+905540085883"
          className="flex justify-center items-center w-12 h-12 text-gray-500 hover:text-gray-900 bg-white rounded-full border border-gray-200 shadow-sm hover:bg-gray-50 focus:ring-4 focus:ring-gray-300"
          aria-label="Telefon"
        >
          <FaPhoneAlt className="w-5 h-5" />
        </a>

        <a
          href="mailto:bthnankara@gmail.com"
          className="flex justify-center items-center w-12 h-12 text-gray-500 hover:text-gray-900 bg-white rounded-full border border-gray-200 shadow-sm hover:bg-gray-50 focus:ring-4 focus:ring-gray-300"
          aria-label="E-posta"
        >
          <FaEnvelope className="w-5 h-5" />
        </a>

        <a
          href="https://t.me/905540085883"
          target="_blank"
          rel="noopener noreferrer"
          className="flex justify-center items-center w-12 h-12 text-gray-500 hover:text-gray-900 bg-white rounded-full border border-gray-200 shadow-sm hover:bg-gray-50 focus:ring-4 focus:ring-gray-300"
          aria-label="Telegram"
        >
          <FaTelegramPlane className="w-5 h-5" />
        </a>

        <a
          href="https://wa.me/905540085883"
          target="_blank"
          rel="noopener noreferrer"
          className="flex justify-center items-center w-12 h-12 text-gray-500 hover:text-gray-900 bg-white rounded-full border border-gray-200 shadow-sm hover:bg-gray-50 focus:ring-4 focus:ring-gray-300"
          aria-label="WhatsApp"
        >
          <FaWhatsapp className="w-5 h-5" />
        </a>
      </div>

      {/* Toggle Butonu */}
      <button
        ref={buttonRef} // Toggle butonuna referans ekliyoruz
        type="button"
        onClick={toggleDial}
        className="flex items-center justify-center w-14 h-14 text-white bg-[#ED7D31] rounded-full hover:bg-[#FF8A33] focus:ring-4 focus:ring-[#FFD09B]"
        aria-label="Aksiyon menüsünü aç"
      >
        {isOpen ? (
          /* X İşareti SVG */
          <svg
            className="w-6 h-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        ) : (
          /* Kişi Desteği SVG */
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2em"
            height="2em"
            viewBox="0 0 16 16"
          >
            <path
              fill="white"
              d="M8 12a1.5 1.5 0 0 1-1.474-1.22a5 5 0 0 1-2.474-1.712a5 5 0 1 1 8.924-3.567c.027.275-.2.499-.476.499s-.497-.225-.53-.499a4 4 0 1 0-5.285 4.278A1.5 1.5 0 1 1 8 12m-4-1.5v-.027a6 6 0 0 1-.748-.805A1.5 1.5 0 0 0 3 10.5v.5c0 1.971 1.86 4 5 4s5-2.029 5-4v-.5A1.5 1.5 0 0 0 11.5 9H10c.219.29.375.63.45 1h1.05a.5.5 0 0 1 .5.5v.5c0 1.438-1.432 3-4 3s-4-1.562-4-3zM8 8a2.5 2.5 0 0 0-1.572.556A2.99 2.99 0 0 1 5 6a3 3 0 1 1 4.572 2.556A2.5 2.5 0 0 0 8 8M6 6a2 2 0 1 0 4 0a2 2 0 0 0-4 0"
            />
          </svg>
        )}
      </button>
    </div>
  );
};

export default SpeedDial;
