// src/App.jsx
import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./pages/Home/Home";
import TourList from "./pages/Tour/TourList";
import TourDetail from "./pages/Tour/TourDetail";
import Register from "./pages/Auth/Register";
import Login from "./pages/Auth/Login";
import BlogDetail from "./pages/Blog/BlogDetail";
import BlogList from "./pages/Blog/BlogList";
import Reservation from "./pages/Cart/Reservation";
import Contact from "./pages/Contact/Contact";
import Service from "./pages/Services/Service";
import About from "./pages/About/About";
import Faq from "./pages/Faq/Faq";
import Payment from "./pages/Cart/Payment";
import Activities from "./pages/Activities/Activities";
import TermsOfService from "./pages/Services/TermsOfService";
import Profile from "./pages/Profile/Profile";
import AdminLogin from "./pages/Admin/AdminLogin";
import AdminRegister from "./pages/Admin/AdminRegister";
import CreateTour from "./pages/Admin/CreateTour";
import ScrollToTop2 from "./components/system/ScrollToTop2";
import BottomTab from "./components/BottomTab";
import Search from "./pages/Search/Search";
import { LANGUAGE_ROUTES } from "./config/routes";
import { useTranslation } from "react-i18next";

function App() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage); // LocalStorage'daki dili uygula
    }
  }, []);

  return (
    <Router>
      <ScrollToTop2 />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/tour-list" element={<TourList />} />

        {/* Dil bazlı dinamik route */}
        {Object.entries(LANGUAGE_ROUTES).map(([language, route]) => (
          <Route
            key={language}
            path={`/${route}/:slug`}
            element={<TourDetail />}
          />
        ))}

        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/blog-detail/:blogId" element={<BlogDetail />} />
        <Route path="/blog-list" element={<BlogList />} />
        <Route path="/reservation" element={<Reservation />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/service" element={<Service />} />
        <Route path="/about" element={<About />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/activities" element={<Activities />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/admin/app/editor/login" element={<AdminLogin />} />
        <Route path="/admin/app/editor/register" element={<AdminRegister />} />
        <Route path="/admin/app/editor/createTour" element={<CreateTour />} />
        <Route path="/search" element={<Search />} />
      </Routes>
      <BottomTab />
    </Router>
  );
}

export default App;
