import React, { useState } from "react";
import axios from "axios";

const CreateTour = () => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    difference: "",
    content: [
      {
        subtitle: "",
        paragraph: "",
        image: "",
      },
    ],
    plan: [
      {
        day: "",
        details: [{ title: "", description: "", image: "" }],
      },
    ],
    priceTable: [
      {
        name: "",
        price: 0,
      },
    ],
    image: "",
    gallery: [""],
  });

  const handleInputChange = (e, field, index, subField, nestedIndex) => {
    if (nestedIndex !== undefined && subField) {
      const updatedArray = [...formData[field]];
      updatedArray[index].details[nestedIndex][subField] = e.target.value;
      setFormData({ ...formData, [field]: updatedArray });
    } else if (index !== undefined && subField) {
      const updatedArray = [...formData[field]];
      updatedArray[index][subField] = e.target.value;
      setFormData({ ...formData, [field]: updatedArray });
    } else if (index !== undefined) {
      const updatedArray = [...formData[field]];
      updatedArray[index] = e.target.value;
      setFormData({ ...formData, [field]: updatedArray });
    } else {
      setFormData({ ...formData, [field]: e.target.value });
    }
  };

  const handleRemoveItem = (field, index, nestedIndex) => {
    if (nestedIndex !== undefined) {
      const updatedArray = [...formData[field]];
      updatedArray[index].details = updatedArray[index].details.filter(
        (_, i) => i !== nestedIndex
      );
      setFormData({ ...formData, [field]: updatedArray });
    } else {
      setFormData((prev) => ({
        ...prev,
        [field]: prev[field].filter((_, i) => i !== index),
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://your-api-url.com/api/tour",
        formData
      );
      if (response.data.success) {
        alert("Tour created successfully!");
        setFormData({
          title: "",
          description: "",
          difference: "",
          content: [{ subtitle: "", paragraph: "", image: "" }],
          plan: [
            { day: "", details: [{ title: "", description: "", image: "" }] },
          ],
          priceTable: [{ name: "", price: 0 }],
          image: "",
          gallery: [""],
        });
      }
    } catch (error) {
      console.error("Error creating tour:", error);
      alert(
        "Failed to create the tour. Please check the console for more details."
      );
    }
  };

  return (
    <div className="flex min-h-screen bg-gray-50">
      {/* Form Editor */}
      <div className="w-1/2 p-6 bg-white shadow-lg overflow-y-auto">
        <h1 className="text-2xl font-bold mb-6">Create New Tour</h1>
        <form onSubmit={handleSubmit}>
          {/* Title */}
          <div className="mb-4">
            <label className="block text-gray-700 mb-1">Title</label>
            <input
              type="text"
              className="w-full p-2 border rounded"
              value={formData.title}
              onChange={(e) => handleInputChange(e, "title")}
            />
          </div>

          {/* Description */}
          <div className="mb-4">
            <label className="block text-gray-700 mb-1">Description</label>
            <textarea
              className="w-full p-2 border rounded"
              value={formData.description}
              onChange={(e) => handleInputChange(e, "description")}
            />
          </div>

          {/* Main Image */}
          <div className="mb-4">
            <label className="block text-gray-700 mb-1">Main Image URL</label>
            <input
              type="text"
              className="w-full p-2 border rounded"
              value={formData.image}
              onChange={(e) => handleInputChange(e, "image")}
            />
          </div>

          {/* Content */}
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Content</h3>
            {formData.content.map((item, index) => (
              <div key={index} className="mb-4 border rounded p-4 bg-gray-50">
                <label className="block text-gray-700 mb-1">Subtitle</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  value={item.subtitle}
                  onChange={(e) =>
                    handleInputChange(e, "content", index, "subtitle")
                  }
                />
                <label className="block text-gray-700 mb-1 mt-2">
                  Paragraph
                </label>
                <textarea
                  className="w-full p-2 border rounded"
                  value={item.paragraph}
                  onChange={(e) =>
                    handleInputChange(e, "content", index, "paragraph")
                  }
                />
                <label className="block text-gray-700 mb-1 mt-2">
                  Image URL
                </label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  value={item.image}
                  onChange={(e) =>
                    handleInputChange(e, "content", index, "image")
                  }
                />
                <button
                  type="button"
                  className="text-red-500 mt-2"
                  onClick={() => handleRemoveItem("content", index)}
                >
                  Remove Content
                </button>
              </div>
            ))}
            <button
              type="button"
              className="bg-blue-500 text-white py-1 px-4 rounded"
              onClick={() =>
                setFormData((prev) => ({
                  ...prev,
                  content: [
                    ...prev.content,
                    { subtitle: "", paragraph: "", image: "" },
                  ],
                }))
              }
            >
              Add Content
            </button>
          </div>

          {/* Plan */}
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Plan</h3>
            {formData.plan.map((day, index) => (
              <div key={index} className="mb-4 border rounded p-4 bg-gray-50">
                <label className="block text-gray-700 mb-1">Day</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  value={day.day}
                  onChange={(e) => handleInputChange(e, "plan", index, "day")}
                />
                {day.details.map((detail, detailIndex) => (
                  <div key={detailIndex} className="mt-2">
                    <label className="block text-gray-700 mb-1">Title</label>
                    <input
                      type="text"
                      className="w-full p-2 border rounded"
                      value={detail.title}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          "plan",
                          index,
                          "title",
                          detailIndex
                        )
                      }
                    />
                    <label className="block text-gray-700 mb-1 mt-2">
                      Description
                    </label>
                    <textarea
                      className="w-full p-2 border rounded"
                      value={detail.description}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          "plan",
                          index,
                          "description",
                          detailIndex
                        )
                      }
                    />
                    <label className="block text-gray-700 mb-1 mt-2">
                      Image URL
                    </label>
                    <input
                      type="text"
                      className="w-full p-2 border rounded"
                      value={detail.image}
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          "plan",
                          index,
                          "image",
                          detailIndex
                        )
                      }
                    />
                    <button
                      type="button"
                      className="text-red-500 mt-2"
                      onClick={() =>
                        handleRemoveItem("plan", index, detailIndex)
                      }
                    >
                      Remove Detail
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  className="text-blue-500 mt-2"
                  onClick={() =>
                    setFormData((prev) => {
                      const updatedPlan = [...prev.plan];
                      updatedPlan[index].details.push({
                        title: "",
                        description: "",
                        image: "",
                      });
                      return { ...prev, plan: updatedPlan };
                    })
                  }
                >
                  Add Detail
                </button>
              </div>
            ))}
            <button
              type="button"
              className="bg-blue-500 text-white py-1 px-4 rounded"
              onClick={() =>
                setFormData((prev) => ({
                  ...prev,
                  plan: [...prev.plan, { day: "", details: [] }],
                }))
              }
            >
              Add Day
            </button>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="bg-green-500 text-white py-2 px-4 rounded mt-4"
          >
            Create Tour
          </button>
        </form>
      </div>

      {/* Preview */}
      <div className="w-1/2 p-6 bg-gray-100 overflow-y-auto">
        <h1 className="text-2xl font-bold mb-6">Tour Preview</h1>
        <div className="border rounded-lg p-4 bg-white shadow-lg">
          <h2 className="text-xl font-bold mb-4">
            {formData.title || "Tour Title"}
          </h2>
          <img
            src={formData.image || "https://via.placeholder.com/400x200"}
            alt="Tour Preview"
            className="w-full h-48 object-cover mb-4 rounded"
          />
          <p className="text-gray-700">
            {formData.description || "Tour description..."}
          </p>

          {/* Price Table */}
          {formData.priceTable.length > 0 && (
            <div className="mt-4">
              <h3 className="font-semibold">Prices</h3>
              <ul>
                {formData.priceTable.map((price, index) => (
                  <li key={index} className="flex justify-between">
                    <span>{price.name || "Price Name"}</span>
                    <span>€{price.price || 0}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* Content */}
          {formData.content.length > 0 && (
            <div className="mt-6">
              <h3 className="text-lg font-semibold">Content</h3>
              {formData.content.map((item, index) => (
                <div key={index} className="mt-2">
                  <h4 className="text-gray-800 font-semibold">
                    {item.subtitle}
                  </h4>
                  <p className="text-gray-600">{item.paragraph}</p>
                  {item.image && (
                    <img
                      src={item.image}
                      alt={item.subtitle}
                      className="w-full h-40 object-cover mt-2 rounded"
                    />
                  )}
                </div>
              ))}
            </div>
          )}

          {/* Plan */}
          {formData.plan.length > 0 && (
            <div className="mt-6">
              <h3 className="text-lg font-semibold">Plan</h3>
              {formData.plan.map((day, index) => (
                <div key={index} className="mt-4">
                  <h4 className="text-gray-800 font-semibold">{day.day}</h4>
                  {day.details.map((detail, detailIndex) => (
                    <div key={detailIndex} className="mt-2">
                      <h5 className="text-gray-700">{detail.title}</h5>
                      <p className="text-gray-600">{detail.description}</p>
                      {detail.image && (
                        <img
                          src={detail.image}
                          alt={detail.title}
                          className="w-full h-40 object-cover mt-2 rounded"
                        />
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateTour;
