import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LANGUAGE_ROUTES = {
  en: "tour",
  tr: "tur",
  de: "reisen",
  ru: "тур",
};

const TourCard = ({ tour, currentLanguage }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleCardClick = () => {
    const route = LANGUAGE_ROUTES[currentLanguage] || "tour";
    navigate(`/${route}/${tour.slug?.[currentLanguage]}`);
  };

  return (
    <div
      onClick={handleCardClick}
      className="bg-white shadow-lg rounded-md overflow-hidden flex flex-col transition-transform transform hover:scale-105 cursor-pointer"
    >
      <img
        src={tour.image}
        alt={tour.title?.[currentLanguage] || "No Title"}
        className="h-48 w-full object-cover"
      />
      <div className="p-4 flex flex-col justify-between flex-grow">
        <h3 className="text-lg font-bold text-[#4F4A45] mb-2">
          {tour.title?.[currentLanguage] || "No Title"}
        </h3>
        <p className="text-sm text-[#6C5F5B] mb-4">
          {tour.description?.[currentLanguage]?.slice(0, 100) ||
            "No Description"}
          ...
        </p>
        <p className="text-[#ED7D31] font-bold">
          Price: €{tour.priceTable?.[0]?.price || "N/A"}
        </p>
      </div>
    </div>
  );
};

export default TourCard;
