import React, { useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Search = () => {
  const [searchResults, setSearchResults] = useState([]);
  const savedLanguage = localStorage.getItem("language");
  const handleSearch = async (query) => {
    try {
      const response = await fetch(
        `https://travoktravel.com/api/v1/tour/autoComplete/${savedLanguage}/${query}`
      );
      const data = await response.json();
      setSearchResults(data.data || []);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  return (
    <>
      <Header />
      <div className="container mx-auto px-4 mt-24">
        {/* Search Input */}
        <div className="relative flex items-center justify-center p-1 shadow-xl bg-gray-100 rounded-full">
          <input
            type="text"
            placeholder="Search..."
            onChange={(e) => handleSearch(e.target.value)}
            className="flex-grow px-4 py-4 rounded-l-full text-[#4F4A45] placeholder-[#6C5F5B] focus:outline-none"
          />
          <button
            onClick={() => handleSearch("")}
            className="px-6 py-3 bg-[#ED7D31] text-white rounded-r-full hover:bg-[#6C5F5B] focus:outline-none"
          >
            Search
          </button>
        </div>

        {/* Search Results */}
        <div className="mt-8 pb-24">
          {searchResults.length > 0 ? (
            <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {searchResults.map((result) => (
                <li
                  key={result._id}
                  className="border rounded-lg shadow-lg p-4 bg-white"
                >
                  <img
                    src={result.image || "https://via.placeholder.com/150"}
                    alt={result.title.en}
                    className="w-full h-32 object-cover rounded-t-md"
                  />
                  <h3 className="text-lg font-semibold text-[#4F4A45] mt-2">
                    {result.title.en}
                  </h3>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-center text-gray-600">No results found.</p>
          )}
        </div>
      </div>
    </>
  );
};

export default Search;
