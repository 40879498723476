import React, { useState } from "react";
import { FaEnvelope, FaLock } from "react-icons/fa";
import tourImage from "../../assets/img/tour.jpg"; 
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Login = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        "https://travoktravel.com/api/v1/user/login",
        {
          email: formData.email,
          password: formData.password,
        }
      );

      setLoading(false);
      localStorage.setItem("user", JSON.stringify(response.data.data));
      navigate("/");
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className="relative flex justify-center items-center min-h-screen">
      {/* Background Image */}
      <img
        src={tourImage}
        alt="Tour Background"
        className="absolute top-0 left-0 w-full h-full object-cover z-0 opacity-60"
      />

      {/* Form Section */}
      <div className="relative z-10 max-w-lg mx-4 p-8 bg-white bg-opacity-90 shadow-lg rounded-lg">
        <h2 className="text-2xl font-bold text-center mb-6 text-[#ED7D31]">
          Login
        </h2>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email Address
            </label>
            <div className="relative">
              <FaEnvelope className="absolute left-3 top-3 text-gray-400" />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
                className="w-full pl-10 pr-4 py-2 mt-1 border border-gray-300 rounded-lg focus:outline-none focus:border-[#ED7D31]"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <div className="relative">
              <FaLock className="absolute left-3 top-3 text-gray-400" />
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                required
                className="w-full pl-10 pr-4 py-2 mt-1 border border-gray-300 rounded-lg focus:outline-none focus:border-[#ED7D31]"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              disabled={loading}
              className={`w-full ${
                loading ? "bg-gray-400" : "bg-[#ED7D31]"
              } text-white px-4 py-2 rounded-lg hover:bg-[#d96928] transition-all`}
            >
              {loading ? "Logging in..." : "Login"}
            </button>
          </div>
        </form>

        <p className="text-sm text-center text-gray-500 mt-6">
          Don't have an account?{" "}
          <a href="/register" className="text-[#ED7D31] hover:underline">
            Register here
          </a>
        </p>
      </div>
    </div>
  );
};

export default Login;
