import React from "react";
import Header from "../../components/Header";
import contactBanner from "../../assets/img/alanya.jpg"; // Placeholder image
import Footer from "../../components/Footer";
import { useTranslation } from "react-i18next";


const About = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language.split("-")[0] || "en";
  return (
    <div>
      {/* Header */}
      <Header />

      {/* Banner Image */}
      <div className="w-full h-[400px] relative">
        <img
          src={contactBanner}
          alt="Contact Us"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <h1 className="text-3xl md:text-4xl font-bold text-white">
          {t("aboutUs.aboutUs")}
          </h1>
        </div>
      </div>

      {/* New Animated Section */}
      <div className="flex flex-col md:flex-row items-center justify-between py-16 px-4 md:px-8">
        {/* Left Side - Animated Images */}
        <div className="relative w-full md:w-1/2 h-auto flex justify-center items-center space-x-4">
          {/* Main Image */}
          <img
            src={contactBanner}
            alt="Alanya"
            className="w-[300px] h-[250px] object-cover rounded-lg shadow-lg transform hover:scale-105 transition-all duration-500 ease-in-out"
          />

          {/* Top Left Image with Y-axis animation */}
          <div className="absolute -left-1 top-8 animate-slideY">
            <img
              src={contactBanner}
              alt="Alanya"
              className="w-[150px] h-[150px] object-cover rounded-lg shadow-lg transform hover:scale-105 transition-all duration-500 ease-in-out"
            />
          </div>

          {/* Bottom Right Image with X-axis animation */}
          <div className="absolute right-0 bottom-8 animate-slideX">
            <img
              src={contactBanner}
              alt="Alanya"
              className="w-[150px] h-[150px] object-cover rounded-lg shadow-lg transform hover:scale-105 transition-all duration-500 ease-in-out"
            />
          </div>
        </div>

        {/* Right Side - Text Content */}
        <div className="mt-8 md:mt-0 md:ml-12 w-full md:w-1/2">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">
            Welcome To Travel
          </h2>
          <p className="text-lg text-gray-600 mb-4">
          {t("aboutUs.desc")}
          </p>
          <ul className="space-y-4">
            <li className="flex items-start">
              <span className="text-teal-500 mr-4">•</span>
              <span>
                Exclusive Trip: There are many variations of passages available
                but the majority.
              </span>
            </li>
            <li className="flex items-start">
              <span className="text-teal-500 mr-4">•</span>
              <span>
                Safety First Always: There are many variations of passages
                available but the majority.
              </span>
            </li>
            <li className="flex items-start">
              <span className="text-teal-500 mr-4">•</span>
              <span>
                Professional Guide: There are many variations of passages
                available but the majority.
              </span>
            </li>
          </ul>
          <button className="mt-6 bg-teal-500 hover:bg-teal-600 text-white font-bold py-3 px-6 rounded-lg shadow-lg">
            Contact With Us
          </button>
        </div>
      </div>

      {/* Animation Styles */}
      <style jsx>{`
        @keyframes slideX {
          0% {
            transform: translateX(0);
          }
          50% {
            transform: translateX(20px);
          }
          100% {
            transform: translateX(0);
          }
        }

        @keyframes slideY {
          0% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(20px);
          }
          100% {
            transform: translateY(0);
          }
        }

        .animate-slideX {
          animation: slideX 3s ease-in-out infinite;
        }

        .animate-slideY {
          animation: slideY 3s ease-in-out infinite;
        }
      `}</style>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default About;
