import React, { useState, useEffect, useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../assets/img/logo.png";
import userAvatar from "../assets/img/user.png";
import enFlag from "../assets/img/en.png";
import trFlag from "../assets/img/tr.png";
import deFlag from "../assets/img/de.png";
import ruFlag from "../assets/img/ru.png";

const normalizeCategoryName = (name) =>
  name
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w-]/g, "");

const Header = ({ scrollToCategory }) => {
  const { t, i18n } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);
  const [currencyDropdownOpen, setCurrencyDropdownOpen] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("GBP"); // Default currency set to GBP

  const dropdownRef = useRef(null);
  const avatarButtonRef = useRef(null);
  const languageDropdownRef = useRef(null);
  const currencyDropdownRef = useRef(null);

  // Döviz Sembolü ve Değerleri
  const dropdownCurrencies = [
    { code: "GBP", symbol: "£", rate: "44.41" },
    { code: "USD", symbol: "$", rate: "34.21" },
    { code: "EUR", symbol: "€", rate: "36.79" },
  ];

  const flagMap = {
    en: enFlag,
    tr: trFlag,
    de: deFlag,
    ru: ruFlag,
  };

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const toggleLanguageDropdown = () =>
    setLanguageDropdownOpen(!languageDropdownOpen);
  const toggleCurrencyDropdown = () =>
    setCurrencyDropdownOpen(!currencyDropdownOpen);

  const handleCategoryClick = (categoryKey) => {
    const categoryName = t(`categories.${categoryKey}`, categoryKey);
    const normalizedCategory = normalizeCategoryName(categoryName);
    scrollToCategory(normalizedCategory);
  };

  const handleCurrencyChange = (currency) => {
    setSelectedCurrency(currency.code);
    setCurrencyDropdownOpen(false); // Close the dropdown after selecting a currency
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        avatarButtonRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !avatarButtonRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
      if (
        languageDropdownRef.current &&
        !languageDropdownRef.current.contains(event.target)
      ) {
        setLanguageDropdownOpen(false);
      }
      if (
        currencyDropdownRef.current &&
        !currencyDropdownRef.current.contains(event.target)
      ) {
        setCurrencyDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header className="fixed top-0 left-0 w-full bg-[#4F4A45] z-40 shadow-md">
      <div className="py-4 px-4 sticky top-0 bg-[#F6F1EE] shadow-md">
        <div className="container mx-auto flex justify-between items-center">
          {/* Logo */}
          <a href="/" className="flex items-center space-x-2">
            <img src={logo} alt="Logo" className="w-25 h-10 object-contain" />
          </a>

          {/* Döviz Seçici */}
          <div className="relative ml-2" ref={currencyDropdownRef}>
            <button
              onClick={toggleCurrencyDropdown}
              className="flex items-center text-[#ED7D31] hover:text-[#4F4A45]"
            >
              <span className="mr-2">
                {
                  dropdownCurrencies.find((c) => c.code === selectedCurrency)
                    ?.symbol
                }
              </span>
              {selectedCurrency}
            </button>
            {currencyDropdownOpen && (
              <div className="absolute right-0 w-24 mt-2 bg-white shadow-md rounded-md">
                {dropdownCurrencies.map((currency) => (
                  <button
                    key={currency.code}
                    onClick={() => handleCurrencyChange(currency)}
                    className="block py-2 text-[#4F4A45] w-full hover:bg-[#ED7D31] hover:text-white flex items-center"
                  >
                    <span className="mr-2">{currency.symbol}</span>
                    {currency.code} - {currency.rate}{" "}
                    {/* Display currency rate */}
                  </button>
                ))}
              </div>
            )}
          </div>

          <div className="flex items-center space-x-4">
            {/* Navigation */}
            <nav className="hidden xl:flex space-x-8">
              <a href="/" className="text-[#ED7D31] hover:text-[#4F4A45] py-2">
                {t("header.home")}
              </a>
              <button
                onClick={() => handleCategoryClick("daily-boat-tours")}
                className="text-[#ED7D31] hover:text-[#4F4A45] py-2"
              >
                {t("header.dailyBoatTours")}
              </button>
              <button
                onClick={() => handleCategoryClick("family-tours")}
                className="text-[#ED7D31] hover:text-[#4F4A45] py-2"
              >
                {t("header.familyTours")}
              </button>
              <a
                href="/blog-list"
                className="text-[#ED7D31] hover:text-[#4F4A45] py-2"
              >
                {t("header.blogs")}
              </a>
              <a
                href="/contact"
                className="text-[#ED7D31] hover:text-[#4F4A45] py-2"
              >
                {t("header.contactUs")}
              </a>
            </nav>

            {/* Language Selector */}
            <div className="relative" ref={languageDropdownRef}>
              <button
                onClick={toggleLanguageDropdown}
                className="flex items-center text-[#ED7D31] hover:text-[#4F4A45]"
              >
                <img
                  src={flagMap[i18n.language] || enFlag}
                  alt="Flag"
                  className="w-5 h-5 mr-2"
                />
                {i18n.language.toUpperCase()}
              </button>
              {languageDropdownOpen && (
                <div className="absolute right-0 w-24 mt-2 bg-white shadow-md rounded-md">
                  {Object.entries(flagMap).map(([lng, flag]) => (
                    <button
                      key={lng}
                      className="block py-2 text-[#4F4A45] w-full hover:bg-[#ED7D31] hover:text-white flex items-center"
                      onClick={() => i18n.changeLanguage(lng)}
                    >
                      <img
                        src={flag}
                        alt={`${lng} Flag`}
                        className="w-5 h-5 mr-2"
                      />
                      {lng.toUpperCase()}
                    </button>
                  ))}
                </div>
              )}
            </div>

            {/* Avatar Dropdown */}
            <div className="relative" ref={dropdownRef}>
              <button
                onClick={toggleDropdown}
                className="flex items-center text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-[#ED7D31]"
                type="button"
                ref={avatarButtonRef}
              >
                <img
                  className="w-8 h-8 rounded-full"
                  src={userAvatar}
                  alt="User Avatar"
                />
              </button>
              {dropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-[#ED7D31] rounded-lg shadow-lg z-50">
                  <div
                    onClick={() => navigate("/profile")}
                    className="px-4 py-3 text-sm text-white hover:bg-[#F6F1EE] rounded-lg hover:text-[#ED7D31] cursor-pointer"
                  >
                    {user ? (
                      <div>
                        {user.name} {user.surname}
                      </div>
                    ) : (
                      <div>{t("header.guestUser")}</div>
                    )}
                  </div>
                  <ul className="py-0 text-sm text-gray-200">
                    {user ? (
                      <li
                        onClick={() => {
                          localStorage.removeItem("user");
                          setDropdownOpen(false);
                          navigate("/login");
                        }}
                        className="px-4 py-3 hover:bg-[#F6F1EE] hover:text-[#ED7D31] cursor-pointer"
                      >
                        {t("header.logout")}
                      </li>
                    ) : (
                      <>
                        <li
                          onClick={() => navigate("/register")}
                          className="px-4 py-3 hover:bg-[#F6F1EE] hover:text-[#ED7D31] cursor-pointer"
                        >
                          {t("header.register")}
                        </li>
                        <li
                          onClick={() => navigate("/login")}
                          className="px-4 py-3 hover:bg-[#F6F1EE] hover:text-[#ED7D31] cursor-pointer"
                        >
                          {t("header.login")}
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              )}
            </div>
          </div>

          {/* Mobile Menu Button */}
          <div className="xl:hidden flex">
            <button onClick={toggleMenu}>
              {menuOpen ? (
                <FaTimes className="text-[#4F4A45] text-2xl" />
              ) : (
                <FaBars className="text-[#4F4A45] text-2xl" />
              )}
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
