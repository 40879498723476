import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import pp1 from "../assets/img/pp1.jpg";
import pp2 from "../assets/img/pp2.jpg";
import pp3 from "../assets/img/pp3.jpg";

const testimonials = [
  {
    name: "Andrew Simon",
    role: "Traveller",
    review:
      "A sophisticated rainwater harvesting system collects and filters rainwater for irrigation and non-potable uses, reducing reliance on municipal water sources.",
    rating: 5,
    photo: pp1,
  },
  {
    name: "Alex Jordan",
    role: "Traveller",
    review:
      "Throughout the interior, eco-friendly materials like reclaimed wood, bamboo flooring, and recycled glass countertops create a luxurious yet sustainable ambiance.",
    rating: 4,
    photo: pp3,
  },
  {
    name: "Maria Doe",
    role: "Traveller",
    review:
      "A home that perfectly blends sustainability and luxury until I discovered Ecoland Residence. The commitment to eco-friendly living made it feel like home.",
    rating: 5,
    photo: pp2,
  },
  {
    name: "Maria Doe",
    role: "Traveller",
    review:
      "A home that perfectly blends sustainability and luxury until I discovered Ecoland Residence. The commitment to eco-friendly living made it feel like home.",
    rating: 5,
    photo: pp2,
  },
  {
    name: "Maria Doe",
    role: "Traveller",
    review:
      "A home that perfectly blends sustainability and luxury until I discovered Ecoland Residence. The commitment to eco-friendly living made it feel like home.",
    rating: 5,
    photo: pp2,
  },
];

const TestimonialArea = () => {
  return (
    <section className="">
      <div className="container mx-auto px-4">
        {/* Title Section */}
        <div className="">
          <h2 className="text-3xl font-bold text-[#4F4A45]">
            What Clients Say About Us
          </h2>
        </div>

        {/* Testimonial Swiper */}
        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          grabCursor={true}
          pagination={{ clickable: true }}
          modules={[Pagination]}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }}
          className="mySwiper"
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <div className="bg-white mb-1 mt-4 rounded-lg p-6 max-w-md mx-auto flex flex-col items-start h-[300px] border">
                <div className="flex items-center">
                  {/* Placeholder for User Image */}
                  <div className="w-16 h-16 rounded-full bg-gray-300 mb-4 flex items-center justify-center">
                    <img
                      src={testimonial.photo}
                      alt={testimonial.name}
                      className="w-full h-full object-cover rounded-full"
                    />
                  </div>

                  <div className="ml-4">
                    {/* User Info */}
                    <h3 className="text-lg font-semibold text-[#4F4A45]">
                      {testimonial.name}
                    </h3>

                    <div className="flex items-center mt-2 mb-4">
                      {Array(testimonial.rating)
                        .fill()
                        .map((_, i) => (
                          <span key={i} className="text-yellow-500">
                            &#9733;
                          </span>
                        ))}
                    </div>
                  </div>
                </div>

                {/* Rating */}

                {/* Testimonial Review */}
                <p
                  className="text-gray-700 text-left"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 6, // Number of lines before truncation
                  }}
                >
                  {testimonial.review}
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default TestimonialArea;
