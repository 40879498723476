import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useTranslation } from "react-i18next";

const TermsOfService = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language.split("-")[0] || "en";
  return (
    <div>
      <Header />
      {/* Main Content */}
      <div className="container mx-auto px-4 py-16 mt-4">
        <h1 className="text-3xl font-bold mb-8 text-[#4F4A45]">
          Terms of Service
        </h1>
        <div className="space-y-6 text-gray-700">
          <section>
            <h2 className="text-xl font-semibold mb-2">1. Introduction</h2>
            <p>{t("termsOfService.introduction")}</p>
          </section>
          <section>
            <h2 className="text-xl font-semibold mb-2">
              2. User Responsibilities
            </h2>
            <p>{t("termsOfService.userResponsibilities")}</p>
          </section>
          <section>
            <h2 className="text-xl font-semibold mb-2">
              3. Service Limitations
            </h2>
            <p>{t("termsOfService.serviceLimitations")}</p>
          </section>
          <section>
            <h2 className="text-xl font-semibold mb-2">4. Privacy Policy</h2>
            <p>{t("termsOfService.privacyPolicy")}</p>
          </section>
          <section>
            <h2 className="text-xl font-semibold mb-2">5. Changes to Terms</h2>
            <p>{t("termsOfService.changesToTerms")}</p>
          </section>
          <section>
            <h2 className="text-xl font-semibold mb-2">
              6. Contact Information
            </h2>
            <p>
              {t("termsOfService.contactInformation")}{" "}
              <a
                href="mailto:support@travoktravel.com"
                className="text-blue-600 hover:underline"
              >
                support@travoktravel.com
              </a>
              .
            </p>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsOfService;
