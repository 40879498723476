import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { FiFilter } from "react-icons/fi";
import alanya from "../../assets/img/alanya.jpg"; // Varsayılan görsel
import { useNavigate } from "react-router-dom";
import SpeedDial from "../../components/SpeedDial";
import ContactModal from "../../components/ContactModal";
import Footer from "../../components/Footer";
import axios from "axios";

const BlogList = () => {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]); // API'den gelen blogları saklayacak state
  const [currentPage, setCurrentPage] = useState(1);
  const [popularOnly, setPopularOnly] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Yükleme durumu

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const fetchBlogs = async () => {
    try {
      const response = await axios.get(
        "https://travoktravel.com/api/v1/blog/getAllBlogs"
      );
      if (response.data.success) {
        setBlogs(response.data.data); // Blog verilerini state'e kaydediyoruz
      } else {
        alert("Bloglar getirilemedi.");
      }
    } catch (error) {
      console.error("Bloglar alınırken hata oluştu:", error);
    } finally {
      setIsLoading(false); // Yükleme durumu sona erdi
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  const startIndex = (currentPage - 1) * 8;
  const filteredBlogs = blogs.filter((blog) => !popularOnly || blog.popular);
  const selectedBlogs = filteredBlogs.slice(startIndex, startIndex + 8);

  return (
    <div className="relative">
      <Header />
      <SpeedDial />
      {/* Ana İçerik */}
      <div className="container mx-auto mt-20">
        {/* Ana Banner */}
        <div className="bg-white rounded-lg shadow-lg overflow-hidden mb-6">
          <div className="relative w-full h-64 md:h-80">
            <img
              src={alanya}
              alt="Promotional Banner"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
              <div className="text-center text-white px-4">
                <h2 className="text-2xl md:text-3xl font-bold mb-2">
                  26-29 Ekim
                </h2>
                <p className="text-lg md:text-xl">
                  Uzun Hafta Sonu Tatilinin Tadını Çıkar!
                </p>
                <div className="mt-4 bg-orange-600 inline-block px-3 py-1 rounded-full">
                  <span className="text-sm md:text-base">
                    %50'ye Varan İndirimler
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto py-8 mt-[10px] flex flex-col lg:flex-row">
        {/* Filtre Yan Menüsü (Büyük ekranlarda görünür) */}
        <div className="hidden lg:block w-full lg:w-1/5 lg:mr-4">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <h5 className="inline-flex items-center mb-4 text-base font-semibold text-[#ED7D31]">
              Kategoriler
            </h5>
            <div className="space-y-4">
              <div className="space-y-2">
                <h3 className="text-lg font-semibold">Bölgeler</h3>
                <ul className="space-y-2">
                  <li className="flex items-center">
                    <input type="checkbox" className="mr-2" id="region1" />
                    <label htmlFor="region1">Avrupa</label>
                  </li>
                  <li className="flex items-center">
                    <input type="checkbox" className="mr-2" id="region2" />
                    <label htmlFor="region2">Afrika</label>
                  </li>
                  <li className="flex items-center">
                    <input type="checkbox" className="mr-2" id="region3" />
                    <label htmlFor="region3">Akdeniz</label>
                  </li>
                  <li className="flex items-center">
                    <input type="checkbox" className="mr-2" id="region4" />
                    <label htmlFor="region4">Ege</label>
                  </li>
                  <li className="flex items-center">
                    <input type="checkbox" className="mr-2" id="region5" />
                    <label htmlFor="region5">İç Anadolu</label>
                  </li>
                  <li className="flex items-center">
                    <input type="checkbox" className="mr-2" id="region6" />
                    <label htmlFor="region6">Karadeniz</label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Blog Listesi */}
        {isLoading ? (
          <p>Yükleniyor...</p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-6 w-full lg:w-4/5 px-4 md:px-0">
            {selectedBlogs.map((blog) => (
              <div
                key={blog._id}
                onClick={() => navigate(`/blog-detail/${blog._id}`)} // Kartın tamamını tıklanabilir hale getiriyoruz
                className="bg-white rounded-lg overflow-hidden shadow-sm hover:shadow-lg transition-shadow duration-300 cursor-pointer"
              >
                <img
                  src={alanya} // Sabit görsel kullanımı
                  alt={blog.title}
                  className="w-full h-56 object-cover"
                />
                <div className="p-4">
                  <h3 className="text-lg font-bold mb-2">{blog.title}</h3>
                  {/* İçerik 30 karakter ile sınırlandırılıyor */}
                  <p className="text-gray-600 font-light">
                    {blog.content[0]?.paragraph.length > 50
                      ? blog.content[0].paragraph.slice(0, 50) + "..."
                      : blog.content[0]?.paragraph || "Açıklama mevcut değil."}
                  </p>
                  {/* Rating */}
                  <div className="flex items-center mt-4">
                    <span className="text-yellow-500 mr-1">&#9733;</span>
                    <span className="text-yellow-500 mr-1">&#9733;</span>
                    <span className="text-yellow-500 mr-1">&#9733;</span>
                    <span className="text-yellow-500 mr-1">&#9733;</span>
                    <span className="text-yellow-500 mr-1">&#9733;</span>
                    <p className="text-sm ml-2">{blog.rating || "4.8"}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Mobil Çekmece (Drawer) */}
      <div
        className={`fixed top-0 left-0 z-50 h-screen p-4 overflow-y-auto transition-transform bg-white w-64 lg:hidden ${
          isDrawerOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <h5 className="inline-flex items-center mb-4 text-base font-semibold text-[#ED7D31]">
          Filtreler
        </h5>
        <button
          onClick={toggleDrawer}
          className="text-[#ED7D31] bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 right-2.5 flex items-center justify-center"
        >
          <svg
            className="w-3 h-3"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
        </button>
      </div>

      <ContactModal />
      <Footer />
    </div>
  );
};

export default BlogList;
