// src/components/SearchBar.jsx
import React, { useState, useEffect } from "react";
import axios from "axios";

const SearchBar = ({ onSearch }) => {
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const savedLanguage = localStorage.getItem("language");

  const handleInputChange = async (e) => {
    const value = e.target.value;
    setQuery(value);

    if (value.length >= 2) {
      try {
        const response = await axios.get(
          `https://travoktravel.com/api/v1/tour/autoComplete/${savedLanguage}/${value}`
        );
        console.log(response.data.data);
        setSuggestions(response.data.data);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleSearch = () => {
    onSearch(query);
    setSuggestions([]); // Dropdown'ı temizle
  };

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion.title);
    onSearch(suggestion.title);
    setSuggestions([]); // Dropdown'ı temizle
  };

  return (
    <div className="container mx-auto my-12 mt-10">
      <div className="relative flex items-center justify-center p-1 shadow-xl bg-gray-100 rounded-full">
        <input
          type="text"
          placeholder="Search..."
          value={query}
          onChange={handleInputChange}
          className="flex-grow px-4 py-4 rounded-l-full text-[#4F4A45] placeholder-[#6C5F5B] focus:outline-none"
        />
        <button
          onClick={handleSearch}
          className="px-6 py-3 bg-[#ED7D31] text-white rounded-r-full hover:bg-[#6C5F5B] focus:outline-none"
        >
          Search
        </button>

        {/* Dropdown Suggestions */}
        {suggestions.length > 0 && (
          <ul className="absolute top-full left-0 w-full bg-white shadow-md rounded-lg mt-2 z-10">
            {suggestions.map((suggestion) => (
              <li
                key={suggestion._id}
                className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {suggestion.title}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
