import React, { useState } from "react";
import { FaUser, FaEnvelope, FaLock } from "react-icons/fa";
import tourImage from "../../assets/img/tour.jpg";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AdminRegister = () => {
  const navigate = useNavigate();

  // State to handle form input values
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    agreeTerms: false,
  });

  // State for loading
  const [loading, setLoading] = useState(false);

  // Handling form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handling checkbox changes
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  // Handling form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.agreeTerms) {
      alert("You must agree to the terms and conditions.");
    } else {
      setLoading(true);
      try {
        // Sending a POST request to the register endpoint
        await axios.post("https://travoktravel.com/api/v1/admin/register", {
          name: formData.firstName,
          surname: formData.lastName,
          email: formData.email,
          password: formData.password,
        });

        // Navigate to home page on success
        setLoading(false);
        navigate("/");
      } catch (error) {
        setLoading(false);
        alert(error.response?.data?.message || "Registration failed.");
      }
    }
  };

  return (
    <div className="relative flex justify-center items-center min-h-screen">
      {/* Background Image */}
      <img
        src={tourImage}
        alt="Tour Background"
        className="absolute top-0 left-0 w-full h-full object-cover z-0 opacity-60"
      />

      {/* Form Section */}
      <div className="relative z-10 max-w-lg mx-4 p-8 bg-white bg-opacity-90 shadow-lg rounded-lg">
        <h2 className="text-2xl font-bold text-center mb-6 text-[#ED7D31]">
          Admin Register
        </h2>

        <form onSubmit={handleSubmit} className="space-y-4">
          {/* First Name */}
          <div>
            <label
              htmlFor="firstName"
              className="block text-sm font-medium text-gray-700"
            >
              First Name
            </label>
            <div className="relative">
              <FaUser className="absolute left-3 top-3 text-gray-400" />
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                required
                className="w-full pl-10 pr-4 py-2 mt-1 border border-gray-300 rounded-lg focus:outline-none focus:border-[#ED7D31]"
              />
            </div>
          </div>

          {/* Last Name */}
          <div>
            <label
              htmlFor="lastName"
              className="block text-sm font-medium text-gray-700"
            >
              Last Name
            </label>
            <div className="relative">
              <FaUser className="absolute left-3 top-3 text-gray-400" />
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                required
                className="w-full pl-10 pr-4 py-2 mt-1 border border-gray-300 rounded-lg focus:outline-none focus:border-[#ED7D31]"
              />
            </div>
          </div>

          {/* Email */}
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email Address
            </label>
            <div className="relative">
              <FaEnvelope className="absolute left-3 top-3 text-gray-400" />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
                className="w-full pl-10 pr-4 py-2 mt-1 border border-gray-300 rounded-lg focus:outline-none focus:border-[#ED7D31]"
              />
            </div>
          </div>

          {/* Password */}
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <div className="relative">
              <FaLock className="absolute left-3 top-3 text-gray-400" />
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                required
                className="w-full pl-10 pr-4 py-2 mt-1 border border-gray-300 rounded-lg focus:outline-none focus:border-[#ED7D31]"
              />
            </div>
          </div>

          {/* Remember Me */}
          <div className="flex items-start mb-4">
            <div className="flex items-center h-5">
              <input
                id="agreeTerms"
                type="checkbox"
                name="agreeTerms"
                checked={formData.agreeTerms}
                onChange={handleCheckboxChange}
                className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-[#ED7D31]"
              />
            </div>
            <label
              htmlFor="agreeTerms"
              className="ml-2 text-sm font-medium text-gray-900"
            >
              I agree with the{" "}
              <a href="#" className="text-[#ED7D31] hover:underline">
                terms and conditions
              </a>
            </label>
          </div>

          {/* Submit Button */}
          <div>
            <button
              type="submit"
              disabled={loading}
              className={`w-full ${
                loading ? "bg-gray-400" : "bg-[#ED7D31]"
              } text-white px-4 py-2 rounded-lg hover:bg-[#d96928] transition-all`}
            >
              {loading ? "Registering..." : "Register"}
            </button>
          </div>
        </form>

        {/* Link to login */}
        <p className="text-sm text-center text-gray-500 mt-6">
          Already have an account?{" "}
          <button
            onClick={() => navigate("/admin/app/editor/login")}
            className="text-[#ED7D31] hover:underline"
          >
            Login here
          </button>
        </p>
      </div>
    </div>
  );
};

export default AdminRegister;
