import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { EffectCoverflow, Pagination } from "swiper/modules";
import camp from "../assets/img/camp.jpg"; // Placeholder image
import axios from "axios";
import { useTranslation } from "react-i18next";

const CategorySection = ({ scrollToCategory }) => {
  const [categories, setCategories] = useState([]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language.split("-")[0] || "en"; // Kullanıcı dilini belirle

  // Fetch categories from the API
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          "https://travoktravel.com/api/v1/category/getAllCategories"
        );
        if (response.data.success && response.data.data) {
          // Verileri formatla
          const formattedCategories = response.data.data.map((category) => ({
            name: category.displayName[currentLanguage] || category.name,
            image: camp, // Placeholder image
            tours: Math.floor(Math.random() * 20) + 1, // Rastgele turlar sayısı
          }));
          setCategories(formattedCategories);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [currentLanguage]);

  // Handle category click
  const handleCategoryClick = (categoryName) => {
    if (scrollToCategory) {
      scrollToCategory(categoryName);
    }
  };

  return (
    <div className="tours-section py-1 mt-4">
      <div className="container mx-auto px-4 py-2">
        {/* Mobil görünümde Swiper */}
        <div className="block md:hidden">
          <Swiper
            effect={"coverflow"}
            centeredSlides={true}
            slidesPerView={"auto"}
            loop={true}
            spaceBetween={10}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 0.5,
              slideShadows: true,
            }}
            modules={[EffectCoverflow, Pagination]}
            className="mySwiper"
          >
            {categories.map((category, index) => (
              <SwiperSlide
                key={index}
                onClick={() => handleCategoryClick(category.name)}
                className="bg-cover bg-center w-[280px] h-[400px] transform transition-transform hover:scale-105"
              >
                <div className="relative w-full h-full">
                  <img
                    src={category.image}
                    alt={category.name}
                    className="w-full h-full object-cover rounded-lg"
                  />
                </div>
                <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-50 text-white p-4 rounded-b-lg">
                  <h3 className="font-semibold text-lg">{category.name}</h3>
                  {/* <p className="text-sm">{category.tours} Tours Available</p> */}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        {/* Tablet ve desktop görünümde Grid */}
        <div className="hidden md:grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {categories.map((category, index) => (
            <div
              key={index}
              onClick={() => handleCategoryClick(category.name)}
              className="bg-white shadow-md rounded-lg overflow-hidden flex flex-col transform transition-transform hover:scale-105 cursor-pointer"
            >
              <div className="w-full h-48 overflow-hidden">
                <img
                  src={category.image}
                  alt={category.name}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="p-4">
                <h3 className="font-semibold text-lg text-gray-800">
                  {category.name}
                </h3>
                <p className="text-sm text-gray-600">
                  {/* {category.tours} Tours Available */}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CategorySection;
