// src/components/WhyChooseUs.jsx
import React from "react";
import {
  FaRegSmile,
  FaShieldAlt,
  FaMoneyBillWave,
  FaClock,
} from "react-icons/fa";

const WhyChooseUs = () => {
  const features = [
    {
      icon: <FaRegSmile className="text-4xl text-orange-500" />,
      title: "Customer Satisfaction",
      description:
        "Our top priority is ensuring customer satisfaction with exceptional services.",
    },
    {
      icon: <FaShieldAlt className="text-4xl text-orange-500" />,
      title: "Trusted Service",
      description:
        "We provide reliable and secure services to meet your travel needs.",
    },
    {
      icon: <FaMoneyBillWave className="text-4xl text-orange-500" />,
      title: "Affordable Prices",
      description:
        "We offer the best travel experiences at highly competitive prices.",
    },
    {
      icon: <FaClock className="text-4xl text-orange-500" />,
      title: "24/7 Support",
      description:
        "Our team is available 24/7 to assist you with your travel needs.",
    },
  ];

  return (
    <section className="py-2 mt-2">
      <div className="container mx-auto px-4">
        {/* <h2 className="text-2xl font-bold text-center text-[#4F4A45] mb-8">
          Why Choose Us
        </h2> */}
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-4">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center text-center hover:shadow-xl transition-shadow duration-300"
            >
              <div className="mb-4">{feature.icon}</div>
              <h3 className="text-lg font-semibold text-[#4F4A45]">
                {feature.title}
              </h3>
              <p className="text-sm text-[#6C5F5B] mt-2">
                {feature.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
