import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

i18n
  .use(HttpApi) // Çevirileri dosyalardan yüklemek için
  .use(LanguageDetector) // Kullanıcının tarayıcı dilini algılar
  .use(initReactI18next) // React entegrasyonu
  .init({
    fallbackLng: "en", // Dil bulunamazsa İngilizceye düşer
    lng: "en", // Varsayılan dili İngilizce yap
    debug: true,
    backend: {
      loadPath: "/locales/{{lng}}/translation.json", // Çeviri dosyalarının yolu
    },
    interpolation: {
      escapeValue: false, // React zaten XSS koruması sağlıyor
    },
    detection: {
      order: ["querystring", "cookie", "localStorage", "navigator", "htmlTag"],
      caches: ["cookie"], // Dil tercihini tarayıcıda saklar
    },
  });

export default i18n;
