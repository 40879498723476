import React from "react";
import { useNavigate } from "react-router-dom";

const BlogCard = ({ blog }) => {
  const navigate = useNavigate();

  // Navigate on card click
  const handleCardClick = () => {
    navigate(`/blog-detail/${blog._id}`);
  };

  // Kullanılacak görsel
  const backgroundImage = blog.backgroundImage;

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden flex flex-col transition-transform transform hover:scale-105 cursor-pointer">
      {/* Blog Görseli */}
      <div className="w-full h-64 overflow-hidden" onClick={handleCardClick}>
        <img
          src={backgroundImage}
          alt={blog.title}
          className="w-full h-full object-cover"
        />
      </div>

      {/* Blog İçerik */}
      <div className="p-4 flex flex-col justify-between flex-grow">
        {/* Blog Title */}
        <h3 className="text-xl font-bold text-[#4F4A45] mb-2">{blog.title}</h3>

        {/* Blog Content */}
        <p className="text-m text-[#6C5F5B] mb-4">
          {blog.content?.[0]?.paragraph.slice(0, 700)}...
          <span
            className="text-[#ED7D31] hover:underline flex items-center cursor-pointer"
            onClick={handleCardClick}
          >
            Read More <span className="ml-1">&gt;</span>
          </span>
        </p>
      </div>
    </div>
  );
};

export default BlogCard;
