import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  FaHome,
  FaUser,
  FaSearch,
  FaPhone,
  FaShoppingCart,
} from "react-icons/fa";
import SearchModal from "./SearchModal";

const BottomTab = () => {
  const [active, setActive] = useState(0); // Aktif sekme durumu
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal açma/kapatma durumu

  const location = useLocation();

  // Sayfa yollarına göre BottomTab'ın gösterilmemesi gereken yollar
  const excludedPaths = [
    "/login",
    "/register",
    "/admin/app/editor/login",
    "/admin/app/editor/register",
    "/admin/app/editor/createTour",
  ];

  if (excludedPaths.includes(location.pathname)) {
    return null; // Eğer bulunduğunuz yol excludedPaths içerisindeyse BottomTab gösterilmez
  }

  const Menus = [
    { name: "Home", icon: <FaHome size={24} />, route: "/" },
    { name: "Search", icon: <FaSearch size={24} />, route: "/search" },
    { name: "Cart", icon: <FaShoppingCart size={28} />, route: "/reservation" },
    { name: "Contact", icon: <FaPhone size={24} />, route: "/contact" },
    { name: "Profile", icon: <FaUser size={24} />, route: "/profile" },
  ];

  // Modal'ı açma
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Modal'ı kapama
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="fixed bottom-0 left-0 w-full py-3 bg-[#ED7D31] rounded-t-xl shadow-lg z-50 md:hidden">
      {/* Sadece mobilde görünür olacak */}
      <div className="flex justify-between mt-2 items-center h-full px-4 relative">
        {Menus.map((menu, i) => (
          <li
            key={i}
            className="w-1/5 flex flex-col justify-center items-center"
            onClick={() => {
              setActive(i);
              if (menu.action === "search") {
                openModal();
              }
            }}
          >
            <NavLink
              to={menu.route}
              className={({ isActive }) =>
                `flex flex-col items-center ${
                  isActive || i === active ? "text-white" : "text-gray-400"
                }`
              }
            >
              <span
                className={`text-xl cursor-pointer duration-500 ${
                  i === active && "-mt-5 text-[#fff]"
                }`}
              >
                {menu.icon}
              </span>
              <span
                className={`text-sm ${
                  i === active
                    ? "translate-y-2 duration-700 opacity-100"
                    : "opacity-0 translate-y-10"
                }`}
              >
                {menu.name}
              </span>
            </NavLink>
          </li>
        ))}
      </div>

      {/* Modal'ı ekle */}
      <SearchModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default BottomTab;
