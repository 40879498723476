import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { LANGUAGE_ROUTES } from "../../config/routes";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Testimonial from "../../components/Testimonial";
import SpeedDial from "../../components/SpeedDial";
import WhyChooseUs from "../../components/WhyChooseUs";
import BlogList from "../../components/BlogList";

const TourDetail = () => {
  const { slug } = useParams(); // Slug route'dan alınır
  const { i18n } = useTranslation(); // Dil bilgisi alınır
  const navigate = useNavigate();

  const currentLanguage = i18n.language.split("-")[0] || "en"; // Tarayıcı diline göre ayarla

  const [tour, setTour] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const closeModal = (e) => {
    if (e.target.id === "modal-background") {
      setIsModalOpen(false);
    }
  };

  const fetchTourDetail = async (language, slug) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://travoktravel.com/api/v1/tour/getTourBySlug/${language}/${slug}`
      );
      if (response.data.success) {
        setTour(response.data.data);

        // Eğer slug dil değişikliğine göre farklıysa, URL'yi güncelle
        if (response.data.data.slug[language] !== slug) {
          const newSlug = response.data.data.slug[language];
          const newPath = `/${LANGUAGE_ROUTES[language]}/${newSlug}`;
          navigate(newPath, { replace: true });
        }
      } else {
        console.error("Tour not found");
      }
    } catch (error) {
      console.error("Error fetching tour details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // İlk yükleme ve dil değişikliğinde veri çek
  useEffect(() => {
    fetchTourDetail(currentLanguage, slug);
  }, [currentLanguage, slug]);

  // Dil değişikliğini dinle ve slug'ı güncelle
  useEffect(() => {
    const handleLanguageChange = (lng) => {
      if (tour) {
        const newSlug = tour.slug[lng];
        const newPath = `/${LANGUAGE_ROUTES[lng] || "tour"}/${newSlug}`;
        navigate(newPath, { replace: true });
        fetchTourDetail(lng, newSlug); // Yeni slug'a göre veri çek
      }
    };

    i18n.on("languageChanged", handleLanguageChange);

    // Cleanup listener
    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [tour, navigate, i18n]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (!tour) {
    return <p>Tour not found</p>;
  }

  return (
    <>
      <Header />
      <SpeedDial />

      {/* Tour Title and Gallery */}
      <div className="container mx-auto px-4 md:px-6 mt-[100px]">
        <div className="w-full lg:w-3/4 mx-auto">
          <h1 className="text-2xl md:text-2xl font-semibold mb-2">
            {tour.title?.[currentLanguage]}
          </h1>
          {/* Tour Description */}
          <p className="text-sm md:text-base text-gray-600 mb-2 lg:leading-relaxed">
            {tour.description?.[currentLanguage]}
          </p>
          <div className="relative mt-4">
            <img
              src={tour.image}
              alt={tour.title?.[currentLanguage]}
              className="w-full h-[250px] md:h-[400px] object-cover bg-[#F6F1EE] rounded-lg shadow-lg"
            />
            <button
              onClick={toggleModal}
              className="absolute bottom-4 left-4 text-white bg-[#6C5F5B] hover:bg-gray-500 font-medium rounded-lg text-sm px-4 py-2"
            >
              View Gallery
            </button>
          </div>
        </div>
      </div>

      {/* Price Table */}
      <div className="container mx-auto py-4 px-4 md:px-6">
        <div className="w-full lg:w-3/4 mx-auto">
          <h2 className="text-lg md:text-2xl font-semibold mb-3">Prices</h2>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 bg-gray-50 p-4 rounded-lg shadow-lg">
            {tour.priceTable.map((price) => (
              <div key={price._id} className="text-center">
                <h3 className="text-l font-semibold text-gray-800">
                  {price.name?.[currentLanguage]}
                </h3>
                <p className="text-lg text-gray-600">{price.price}€</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Contact Reservation Form */}
      <div className="container mx-auto py-2 px-4 md:px-6">
        <div className="w-full lg:w-3/4 mx-auto">
          <h2 className="text-lg md:text-2xl font-semibold mb-3 text-left">
            Contact for Reservation
          </h2>
          <form className="space-y-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <input
                type="text"
                placeholder="Name"
                className="w-full bg-gray-100 rounded-lg p-2.5 border border-gray-300"
              />
              <input
                type="text"
                placeholder="Surname"
                className="w-full bg-gray-100 rounded-lg p-2.5 border border-gray-300"
              />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <input
                type="tel"
                placeholder="Phone Number"
                className="w-full bg-gray-100 rounded-lg p-2.5 border border-gray-300"
              />
              <input
                type="email"
                placeholder="Email"
                className="w-full bg-gray-100 rounded-lg p-2.5 border border-gray-300"
              />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <input
                type="number"
                placeholder="Number of People"
                min="1"
                className="w-full bg-gray-100 rounded-lg p-2.5 border border-gray-300"
              />
              <input
                type="number"
                placeholder="Number of Children"
                min="0"
                className="w-full bg-gray-100 rounded-lg p-2.5 border border-gray-300"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-[#ED7D31] text-white rounded-lg py-2 mt-4 hover:bg-orange-600 transition-all"
            >
              Submit Reservation Request
            </button>
          </form>
        </div>
      </div>

      {/* Difference Section */}
      <div className="container mx-auto">
        <div className="w-full lg:w-3/4 mx-auto">
          <WhyChooseUs />
        </div>
      </div>

      {/* Plan Section */}
      <div className="container mx-auto py-2 px-4 md:px-6">
        <div className="w-full md:w-3/4 mx-auto">
          {tour.plan.map((day, index) => (
            <div key={index} className="rounded-lg mb-2">
              <h3 className="text-xl font-semibold text-gray-800 mb-2">
                {day.day} Program
              </h3>
              {day.details.map((activity, i) => (
                <div
                  key={i}
                  className="mb-4 flex flex-col md:flex-row items-start gap-4"
                >
                  {activity.image && (
                    <div className="w-full md:w-1/2 rounded-lg overflow-hidden shadow-sm">
                      <img
                        src={activity.image}
                        alt={activity.title?.[currentLanguage]}
                        className="w-full h-60 object-cover"
                      />
                    </div>
                  )}
                  <div className="w-full md:w-1/2 flex flex-col">
                    <h4 className="text-lg font-medium text-[#4F4A45]">
                      {activity.title?.[currentLanguage]}
                    </h4>
                    <p className="text-gray-600 text-sm leading-relaxed">
                      {activity.description?.[currentLanguage]}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>

      {/* Content Section */}
      <div className="container mx-auto py-2 px-4 md:px-6">
        <div className="w-full md:w-3/4 mx-auto pb-2">
          {tour.content.map((section, index) => (
            <div key={index} className="">
              {section.subtitle && (
                <h3 className="text-xl font-semibold text-gray-800 mb-2">
                  {section.subtitle?.[currentLanguage]}
                </h3>
              )}
              {section.image && (
                <div className="bg-red-300 rounded-md overflow-hidden mb-2">
                  <img
                    src={section.image}
                    alt={section.subtitle?.[currentLanguage] || "Content Image"}
                    className="w-full h-64 object-cover"
                  />
                </div>
              )}
              <p className="text-gray-600 mb-2">
                {section.paragraph?.[currentLanguage]}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Modal for Gallery */}
      {isModalOpen && (
        <div
          id="modal-background"
          onClick={closeModal}
          className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
        >
          <div className="relative w-full max-w-4xl h-[50vh] md:h-[70vh] bg-white rounded-lg shadow dark:bg-gray-700">
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              loop={true}
              pagination={{ clickable: true }}
              className="h-full"
            >
              {tour.gallery.map((imgUrl, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={imgUrl}
                    className="w-full h-full object-cover"
                    alt={`Gallery image ${index + 1}`}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
};

export default TourDetail;
