// src/components/SearchModal.jsx
import React from "react";
import SearchBar from "./SearchBar";

const SearchModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null; // Modal açık değilse render etmiyoruz

  // Modal dışına tıklanınca kapanmasını sağlayan fonksiyon
  const handleModalClick = (e) => {
    // Eğer tıklanan yer modalın dışı ise, modalı kapat
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      onClick={handleModalClick} // Modal dışına tıklayınca modalı kapat
    >
      <div className="w-full max-w-md p-6 rounded-lg relative">
        <SearchBar
          onSearch={(query) => console.log("Arama yapılıyor:", query)}
        />
      </div>
    </div>
  );
};

export default SearchModal;
